<script setup lang="ts">
// icons
import { faHeart, faMapMarked, faLocationArrow } from '@fortawesome/pro-regular-svg-icons';
import LogoColouredHorizontal from '~/assets/svg/logo_coloured_horizontal.svg?component';
import LogoWhiteHorizontal from '~/assets/svg/logo_white_horizontal.svg?component';

// types
import { HeaderTheme } from '@/types';

/********************
 * PROPS & EMITS    *
 ********************/
export interface CiHeaderProps {
  loadingName?: string | null;
  loadingTheme?: HeaderTheme | null;
  theme?: HeaderTheme | null;
}
const props = withDefaults(defineProps<CiHeaderProps>(), {
  loadingName: null,
  loadingTheme: null,
  theme: null,
});

const emit = defineEmits<{
  'set-navigation': [value: boolean];
}>();

const navLinkDefault
  = 'after:content=[\' \'] after:block after:h-px after:opacity-0 after:absolute after:ease-in-out after:duration-[250ms] after:transition-all after:w-0 group-hover:after:w-full group-hover:after:opacity-50';

const themeMapping = {
  [HeaderTheme.black]: {
    header: 'bg-[#000000]',
    link: 'group',
    linkText: `${navLinkDefault} text-white after:bg-white`,
  },
  [HeaderTheme.transparent]: {
    header: 'bg-transparent ',
    link: 'group',
    linkText: `${navLinkDefault}`,
  },
  [HeaderTheme.gradient]: {
    header:
      'bg-transparent after:content-[\' \'] after:block after:h-[200%] after:left-1/2 after:max-w-[1400px] after:opacity-60 after:absolute after:w-full after:-z-[1] after:top-0 after:-translate-x-1/2 after:bg-gradient-to-b after:from-[#000] after:to-transparent',
    link: 'group',
    linkText: `${navLinkDefault} text-white after:bg-white`,
  },
};

/********************
 * COMPOSITIONS      *
 ********************/
// const router = useRouter();
const appStore = useAppStore();
const getRouteBaseName = useRouteBaseName();
const loadingStore = useLoadingStore();
const localePath = useLocalePath();
const route = useRoute();
const { showDiscoverMenu, discoverUrl, discoverText } = useDiscoverMenu();

/********************
 * REFS & VARS       *
 ********************/
const routeBaseName = computed(() => {
  return getRouteBaseName(route);
});

const isHome = computed(() => {
  return routeBaseName.value === 'index';
});

const headerTheme = computed(() => {
  if (props.loadingTheme && props.loadingName && loadingStore.isLoading(props.loadingName)) {
    return props.loadingTheme;
  }
  return props.theme;
});

const headerClass = computed(() => {
  return [
    headerTheme.value ? themeMapping[headerTheme.value].header : 'bg-gray-10 md:bg-white',
    isHome.value ? 'absolute w-full' : 'relative',
  ];
});

const linkTextClass = computed(() => {
  return [headerTheme.value ? themeMapping[headerTheme.value].linkText : 'text-black hover:text-primary'];
});

const linkClass = computed(() => {
  return [headerTheme.value && themeMapping[headerTheme.value].link];
});

const themeIsBlackOrGradient = computed(() => {
  return headerTheme.value === HeaderTheme.gradient || headerTheme.value === HeaderTheme.black;
});

const langSwitchType = computed(() => {
  return themeIsBlackOrGradient.value ? 'white' : 'gray';
});

const childType = computed(() => {
  return themeIsBlackOrGradient.value ? 'white' : 'black';
});

const userIconColor = computed(() => {
  return themeIsBlackOrGradient.value;
});

const mapUrl = computed(() => {
  return routeBaseName.value === RBN_SEARCH_MAP ? route.fullPath : localePath({ name: RBN_SEARCH_MAP });
});
</script>

<template>
  <header
    id="top"
    class="header z-[81]"
    :class="headerClass"
  >
    <div class="container py-2">
      <div class="row items-center py-2">
        <div class="col-3 col-md-4 flex lg:hidden">
          <CiNavigationToggle
            :type="childType"
            :open="false"
            class="justify-start"
            @click="emit('set-navigation', true)"
          />
        </div>
        <div class="col-4 col-md-4 order-2 hidden justify-center lg:flex">
          <ul class="m-0 flex list-none flex-row items-center justify-center">
            <li class="">
              <nuxt-link
                :to="mapUrl"
                class="flex items-center px-2 font-medium hover:no-underline"
                :class="linkClass"
              >
                <CiAwesomeIcon
                  class="fill-primary"
                  :icon="faMapMarked"
                  :ratio="'0.9'"
                />
                <span
                  class="relative ml-2"
                  :class="linkTextClass"
                >{{
                  $pgettext('Header Navigation Title', 'Map')
                }}</span>
              </nuxt-link>
            </li>
            <li
              v-if="showDiscoverMenu"
              class=""
            >
              <nuxt-link
                :to="discoverUrl"
                class="flex items-center px-2 font-medium hover:no-underline"
                :class="linkClass"
                data-cy="ci-navigation--discover"
              >
                <CiAwesomeIcon
                  class="fill-info"
                  :icon="faLocationArrow"
                  :ratio="'0.9'"
                />
                <span
                  class="relative ml-2"
                  :class="linkTextClass"
                >{{ discoverText }}</span>
              </nuxt-link>
            </li>
            <li class="">
              <nuxt-link
                :to="localePath({ name: RBN_FAVORITES })"
                class="flex items-center px-2 font-medium hover:no-underline"
                :class="linkClass"
                data-cy="ci-navigation--favorites"
              >
                <CiAwesomeIcon
                  class="fill-danger"
                  :icon="faHeart"
                  :ratio="'0.9'"
                />
                <span
                  class="relative ml-2"
                  :class="linkTextClass"
                >{{
                  $pgettext('Header Navigation Title', 'Bookmarks')
                }}</span>
              </nuxt-link>
            </li>
          </ul>
        </div>
        <div class="col-6 col-md-4 md:order-1">
          <nuxt-link
            :to="localePath('index')"
            class="shrink-1 flex grow justify-center lg:justify-start"
            aria-label="home"
          >
            <LogoColouredHorizontal
              v-if="headerTheme !== HeaderTheme.gradient && headerTheme !== HeaderTheme.black"
              class="w-[10.875rem] max-w-full"
            />
            <LogoWhiteHorizontal
              v-else
              class="w-[10.875rem] max-w-full"
            />
          </nuxt-link>
        </div>
        <div class="col-3 col-md-4 order-3 flex items-center justify-end">
          <CiLanguageSwitch
            :type="langSwitchType"
            class="hidden md:flex"
            label-position="left"
            icon-ratio="1.25"
          />
          <CiUserIcon
            :light-theme="userIconColor"
            class="ml-2"
            size="lg"
          />
        </div>
      </div>
    </div>

    <!-- overlay -->
    <CiOverlay
      v-if="appStore.userMenuOpen"
      class="block lg:hidden"
    />
  </header>
</template>
