<script setup lang="ts">
// icons
import {
  faSearch,
  faBook,
  faBookOpen,
  faHeart,
  faMapMarked,
  faHome,
  faTimes,
  faLocationArrow,
} from '@fortawesome/pro-regular-svg-icons';

/********************
 * PROPS & EMITS    *
 ********************/
export interface CiHeaderProps {
  open?: boolean;
}
const props = withDefaults(defineProps<CiHeaderProps>(), {
  open: false,
});

/********************
 * COMPOSITIONS      *
 ********************/
const appStore = useAppStore();
const localePath = useLocalePath();
const route = useRoute();
const userStore = useUserStore();
const { $gettext, $pgettext } = useGettext();
const { locale } = useI18n();
const { showDiscoverMenu, discoverUrl, discoverText } = useDiscoverMenu();

/********************
 * REFS & VARS       *
 ********************/
const i18nUserStateText = computed(() => {
  return userStore.loggedIn ? $gettext('My Account') : $gettext('Login');
});

/********************
 * HOOKS             *
 ********************/
watch(() => route.name, onRouteNameChange, { immediate: true });

/********************
 * FUNCTIONS         *
 ********************/
function onProfileClicked() {
  if (!userStore.loggedIn) {
    return localePath({
      name: RBN_LOGIN,
      query: {
        redirect: route.fullPath,
      },
    });
  } else {
    appStore.userMenuOpen = true;
    appStore.sidebarOpen = false;
  }
}

function onRouteNameChange() {
  appStore.sidebarOpen = false;
}
</script>

<template>
  <div class="relative">
    <div
      class="fixed left-0 top-0 z-[100] h-screen transition-transform duration-[400ms] ease-out"
      :class="[props.open ? 'translate-x-0' : 'translate-x-[-90vw]']"
      :data-cy="`ci-sidebar--${props.open}`"
    >
      <aside class="scrolling-touch fixed h-screen w-[17.5rem] max-w-[80vw] overflow-auto bg-white text-dark">
        <nav class="sidebar__nav">
          <div class="flex flex-col border-b-[3px] border-primary bg-dark-green">
            <client-only>
              <NuxtLazyHydrate when-visible>
                <button
                  type="button"
                  class="nav-link flex items-center self-end p-4 text-white"
                  @click="onProfileClicked"
                >
                  <span class="mr-4">{{ i18nUserStateText }}</span>
                  <CiUserIcon
                    :with-menu="false"
                    light-theme
                  />
                </button>
              </NuxtLazyHydrate>
            </client-only>
          </div>

          <ul class="m-0 flex flex-col border-b border-gray-20 p-0">
            <li class="nav-item">
              <nuxt-link
                :to="localePath('index')"
                class="nav-link flex items-center p-4 hover:no-underline"
              >
                <CiAwesomeIcon
                  class="fill-primary"
                  :icon="faHome"
                  ratio="0.9"
                />
                <span class="ml-4">{{ $pgettext('Sidebar Navigation Title', 'Home') }}</span>
              </nuxt-link>
            </li>
          </ul>

          <ul class="m-0 flex flex-col border-b border-gray-20 p-0">
            <li class="nav-item">
              <nuxt-link
                :to="localePath({ name: RBN_SEARCH })"
                class="nav-link flex items-center p-4 hover:no-underline"
              >
                <CiAwesomeIcon
                  class="fill-primary"
                  :icon="faSearch"
                  ratio="0.9"
                />
                <span class="ml-4">{{ $pgettext('Sidebar Navigation', 'Search') }}</span>
              </nuxt-link>
            </li>
            <li class="nav-item">
              <nuxt-link
                :to="localePath({ name: RBN_SEARCH_MAP })"
                class="nav-link flex items-center p-4 hover:no-underline"
              >
                <CiAwesomeIcon
                  class="fill-primary"
                  :icon="faMapMarked"
                  ratio="0.9"
                />
                <span class="ml-4">{{ $pgettext('Sidebar Navigation', 'Map') }}</span>
              </nuxt-link>
            </li>
            <li
              v-if="showDiscoverMenu"
              class="nav-item"
            >
              <nuxt-link
                :to="discoverUrl"
                class="nav-link flex items-center p-4 hover:no-underline"
              >
                <CiAwesomeIcon
                  class="fill-primary"
                  :icon="faLocationArrow"
                  ratio="0.9"
                />
                <span class="ml-4">{{ discoverText }}</span>
              </nuxt-link>
            </li>
            <li class="nav-item">
              <nuxt-link
                :to="localePath({ name: RBN_FAVORITES })"
                class="nav-link flex items-center p-4 hover:no-underline"
              >
                <CiAwesomeIcon
                  class="fill-primary"
                  :icon="faHeart"
                  ratio="0.9"
                />
                <span class="ml-4">{{ $pgettext('Header Navigation Title', 'Bookmarks') }}</span>
              </nuxt-link>
            </li>
          </ul>

          <ul class="m-0 flex flex-col border-b border-gray-20 p-0">
            <li class="nav-item">
              <a
                :href="locale === 'de' ? 'https://www.camping.info/magazin/de': 'https://www.camping.info/magazin/en'"
                class="nav-link flex items-center p-4 hover:no-underline"
                target="_blank"
                rel="noopener"
              >
                <CiAwesomeIcon
                  class="fill-primary"
                  :icon="faBookOpen"
                  ratio="0.9"
                />
                <span class="ml-4">{{ $gettext('Magazin') }}</span>
              </a>
            </li>
            <li class="nav-item">
              <a
                href="https://shop.camping.info/"
                class="nav-link flex items-center p-4 hover:no-underline"
                target="_blank"
                rel="noopener"
              >
                <CiAwesomeIcon
                  class="fill-primary"
                  :icon="faBook"
                  ratio="0.9"
                />
                <span class="ml-4">{{ $pgettext('Sidebar Navigation', 'Camping guide') }}</span>
              </a>
            </li>
          </ul>
        </nav>

        <CiLanguageSwitch
          name="sidebar-language-switch"
          class="flex p-4"
          type="primary"
          icon-ratio="0.9"
        />
      </aside>

      <button
        v-if="open"
        class="button button--icon left-[min(80vw,280px)] top-0 h-[56px]"
        type="button"
        :aria-label="$gettext('Close')"
        data-cy="ci-sidebar-close"
        @click="appStore.sidebarOpen = false"
      >
        <CiAwesomeIcon
          class="fill-white"
          :icon="faTimes"
          ratio="1.2"
        />
      </button>
    </div>
  </div>
</template>

<style></style>
